
@font-face {
    font-family: "KoPub Dotum_Pro";
    font-style: normal;
    font-weight: 100;
    src: url("./KoPub\ Dotum_Pro\ Light.woff") format("woff");
  }
  
  @font-face {
    font-family: "KoPub Dotum_Pro";
    font-style: normal;
    font-weight: 500;
    src: url("./KoPub\ Dotum_Pro\ Bold.woff") format("woff");
  }

  @font-face {
    font-family: "KoPub Dotum_Pro";
    font-style: normal;
    font-weight: normal;
    src: url("./KoPub\ Dotum_Pro\ Medium.woff") format("woff");
  } 
/*wordmatch1*/

.wordmatch1-select-painter {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 2%;
    gap: 10px;
}

.wordmatch1-select-painter img {
    width: 50%;
}


.wordmatch1-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.wordmatch1-item {
    border: solid 1px #E0E0E0;
    border-radius: 10px;
    padding: 5%;
}

.wordmatch1-item img {
    border-radius: 15px;
    width: 70%;
}

.wordmatch1-kedu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.wordmatch1-kedu img {
    width: 10%;
}

.wordmatch1-kedu-textdiv {
    border-radius: 50px;
    position: absolute;
    color: white;
    padding: 0% 2%;
    background-color: #FF8C00;
}

.wordmatch1-kedu-textdiv span {
    font-size: 1.5rem;
    font-weight: 500;
}

/*----------------------*/

.wordmatch2-main {
    display: grid;
    grid-template-columns: 6fr 4fr;
}

.wordmatch2-main-left,
.wordmatch2-main-right {
    padding: 0% 2%;
}
.wordmatch2-main-left {
    border-right: dashed 3px #d1d1d1;
    padding-top: 2rem;
}




.wordmatch2-kedu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.wordmatch2-kedu img {
    width: 40%;
}

.wordmatch2-kedu-textdiv {
    border-radius: 50px;
    position: absolute;
    color: white;
    padding: 2% 20%;
    background-color: #FF8C00;
}

.wordmatch2-kedu-textdiv p {
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0;
}

.dialog-body {
    width: 90%;
}

.adamTalk {
    width: 70%;
    display: flex;
    justify-content: flex-start;
}

.profile_image {
    width: 30%;
}

.profile_image img {
    width: 50%;
    border-radius: 50px;
}

.myTalk {
    display: flex;
    justify-content: flex-end;
}

.messege-box {
    border-radius: 5px 10px 10px 10px;
    width: 50%;
    text-align: left;
    padding: 0% 2%;
    display: flex;
    align-items: center;
    color: #B38D2B;
    position: relative;
    background-color: #BBE0E3;
}

.messege-box::before {
    content: "";
    position: absolute;
    top: 0%;
    left: -2px;
    margin-left: -10px;
    border-width: 5px 15px 5px 0;
    border-style: solid;
    border-color: transparent #BBE0E3 transparent transparent;
}

.message-box {
    background-color: #21C997;
    padding: 1.5% 3%;
    display: flex;
    border-radius: 10px 5px 10px 10px;
    align-items: center;
    position: relative;
}
.message-box::before {
    content: "";
    position: absolute;
    top: 0px; 
    right: 0px;
    margin-right: -10px;
    border-width: 5px 0 5px 15px; 
    border-style: solid;
    border-color: transparent transparent transparent #21C997; 
}
.message-box-inside{
    display: flex;
    width: 100%;
    gap: 5px;
}
.message-box-inside-btn{
    background-color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 7px;
    cursor: pointer;
}
.message-box-inside-btn:hover{
    color: #21C997;
}
.mText {
    color: white;
}

/*----------------*/

/*wordmatch3*/
.wordmatch3-main {
    display: grid;
    grid-template-columns: 7.5fr 2.5fr;
}

.wordmatch3-main-left,
.wordmatch3-main-right {
    padding: 0% 3%;
}
.wordmatch3-main-left {
    border-right: dashed 3px #d1d1d1;

}

.wordmatch3-chart{
    pointer-events: none;
}

.wordmatch3-tree-top{
    display: flex;
    justify-content: space-between;
}

.wordmatch3-tree-top-text{
    display: flex;
    align-items: center;
}

.wordmatch3-tree-top-text div{
    border: solid 1px;
    width: 10px;
    height: 10px;
    margin: 0px 5px 0px 10px;
}
#wordmatch3-tree-yes{background-color: orange;}
#wordmatch3-tree-no{background-color: green;}
#wordmatch3-tree-ans{background-color: red;}
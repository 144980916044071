/*arctic1*/

.arctic1-main {
    display: flex;
    justify-content: center;
    margin: 4rem;
}

.arctic1-main img {
    width: auto;
    height: 100%;
    margin-top: 8rem;
}

.arctic-penguinzone {
    position: relative;
}

.arctic-penguinzone img {
    margin: 0;
    padding: 0;
    width: 90%;
}

.penguintext {
    position: absolute;
    top: 30%;
    left: 15%;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: white;
    font-size: 1.2rem;
}

/*-----------------*/

/*arctic1*/

.arctic-game-space{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.arctic-grid {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    border: solid 1px #63ccd6;
}

.grid-cell {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #63ccd6;
    background-image: url("assets/reinforcement/arctic/ice.png");
}

.penguin {
    background-image: url("assets/reinforcement/arctic/penguin2.png");
    background-size: cover;
}

.grid-cell.hit {
    background-image: url("assets/reinforcement/arctic/mine.png");
}

.grid-cell.goal {
    background-image: url("assets/reinforcement/arctic/igloo.png");
    background-size: cover;
}

.penguin-btn-controls{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin-top: 20px;
}
.penguin-btn-controls img{
    width: 3.5rem;
    cursor: pointer;
}
.penguin-btn-controls img:hover{
    transform: scale(1.1,1.1);
    transition: 0.3s;
}
/*------------------*/

/*arctic2*/


.arctic2-main{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.arctic2-main-left{
    border-right: dashed 2px #ddd;
}
.arctic2-main-left img{
    width: 80%;
}
.arctic2-main-right{
    text-align: left;
    padding: 1rem 2rem 0rem 2rem;
}
.arctic2-main-right2{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*-------------------*/
/*arctic3*/

.arctic3-main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;
}
.arctic3-main-left{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.arctic3-simulation{
    background-color: #F0F0F0;
    border: solid 1px #ddd;
    width: 25rem;
    height: 25rem;
}

.arctic3-main-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-between
}

.arctic3-select{
    width: 12rem;
    height: 2rem;
    cursor: pointer;
}

.arctic3-select:hover {
    background-color: #E8F4FE;
}

.arctic3-select:focus {
    border-color: orange;
    background-color: #E8F4FE;
    outline: none;
}

.arctic3-main-right img{
    width: 24rem;
}
/*seasons1*/

.seasons1-main {
    margin-top: 1rem;
    height: 30rem;
    overflow-y: scroll;
}

.seasons1-main img {
    width: 80%;
}

/*table*/

.seasons1-table-space {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}


.seasons1-table-container {
    width: 90%;
    max-height: 400px;
    overflow: auto;
}
.seasons1-table-container::-webkit-scrollbar{
    width: 5px;
    cursor: pointer;
}
.seasons1-table-container::-webkit-scrollbar-thumb{
    background-color: #818181;
    height: 40%;
}

.seasons1-table {
    border: solid 1px grey;
    border-collapse: collapse;
}

.seasons1-table th,
.seasons1-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    width: 1%;
}

.seasons1-table thead th {
    background-color: #525364;
    color: white;
    font-weight: normal;
    position: sticky;
    top: -0.5px;
}

/*---------------------*/

/*seasons2*/


.seasons2-clusterdesc-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    text-align: left;
    justify-content: center;
    gap: 2rem;
    font-size: 0.9rem;
}

.clusteritem-carousel {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 23rem;
}

.clusteritem-carousel-img {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clusteritem-carousel-img img {
    width: 60%;
}

.clusteritem-carousel-caption{
    width: 100%;
    margin: 1% 0% 3% 0%;
}

.clusteritem-carousel-caption-desc {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 80%;
}


.clusteritem-carousel-caption-button {
    display: flex;
    align-items: center;
    justify-content:flex-end;
}

.clusteritem-carousel-img button {
    border: solid 2px #F0F0F0;
    color: #555555;
    border-radius: 50%;
    padding: 5px 12px;
    font-size: 1.3rem;
    margin : 10%;
}


/*--------------------------*/

/*seasons3*/










/*-----------------------------*/


/*seasons4*/

.seasons4-main {
    display: flex;
    justify-content: center;
    padding: 5% 2%;
}

.seasons4-main-map {
    width: 56%;
    position: relative;
    padding: 5% 1%;
}

.seasons4-country-space{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 4rem;
    position: absolute;
    cursor: pointer;
    filter: grayscale(100%); /* 흑백 필터 */
    transition: filter 0.3s;
}

.seasons4-country-space:hover,
.seasons4-country-space.selected{
    filter: grayscale(0%);
}


#uk {
    top: 20%;
    left: 38%;
}
#usa {
    top: 26%;
    left: 8%;
}
#it{
    top: 27%;
    left: 47%;
}
#jp{
    top: 34%;
    left: 80%;
}
#au{
    top: 68%;
    left: 80%;
}





.seasons4-main-right{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}
.seasons4-main-right-first{
    width: 90%;
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 4% 0% 4% 10%;
}
.seasons4-main-right-first div{
    display: flex;
    align-items: center;
}
.seasons4-dateselect{
    margin-right: 10px;
}
.seasons4-dateselect select{
    width: 4rem;
    height: 1.7rem;
    border: solid 1px #dddddd;
    cursor: pointer;
    font-family: 'Pretendard';
    text-align: center;
    margin: 0% 5%;
}
.seasons4-dateselect option{
    cursor: pointer;
}
.seasons4-main-right-second{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.seasons4-main-right-second button{
    border: solid 1px;
    background-color: #6d900e;
    border-radius: 20px;
    color: white;
    padding: 2% 8%;
    font-family: 'Pretendard';
    font-weight: bold;
    margin: 2% 0%;
}
.seasons4-main-right-third{
    width: 90%;
    background-color: #f5f5f5;
    color: #555555;
    border-radius: 20px;
    padding: 2% 0% 2% 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:center;
}
.seasons4-main-right-third h3{
    margin-top: 4px;
}
.seasons4-main-right-third div{
    display: flex;
    margin: 0;
}
.seasons4-main-right-third div p {
    margin: 4px;
}
.seasons4-main-right-third div b {
    margin: 4px;
}
.react-datepicker__input-container input {
    width: 5rem;
    text-align: center;
}

.react-datepicker__input-container input:hover {
    cursor: pointer;
}


/*----------------------------*/
/*news1*/

.preprocessing-main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.preprocessing-main img {
    width: auto;
    height: 100%;
}

.newspaperzone {
    position: relative;
}

.newspaperzone img {
    margin: 0;
    padding: 0;
    width: 100%;
}

.newspapertext {
    position: absolute;
    top: 40%;
    left: 20%;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: 1.1rem;
    width: 55%;
}

.news-textfield {
    width: 100%;
    height: 6rem;
    background-color: rgb(229, 229, 229);
    box-sizing: border-box;
    resize: none;
    font-size: 1rem;
}

.category-textfield {
    width: 80%;
    height: 3rem;
    background-color: rgb(229, 229, 229);
    box-sizing: border-box;
    resize: none;
    font-size: 1rem;
}


/*------------------------------------*
/*news1*/
.news1-preprocessing {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1%;
    margin: 2% 0%;
}


.news1-newspaper-space {
    width: 40%;
    position: relative;
}

.news1-newspaper-inside {
    position: absolute;
    width: 80%;
    height: 50%;
    margin: -57% 13%;
}
.news1-newspaper-categoryselect{
    display: flex;
    align-items: center;
    position: absolute;
    top: -32%;
    right: 0%;
    font-size: 1.1rem;
}

.news1-newspaper-inside-left {
    letter-spacing: -0.9px;
    color: #777777;
    text-align: left;
    height: 100%;
}

.news1-newspaper-inside-right {
    height: 100%;
}
.news1-newspaper-categoryselect select{
    cursor: pointer;
    color: #555555;
    padding: 2px;
    text-align: center;
    margin-left: 5px;
}

.news1-textfield {
    font-family: 'Pretendard';
    width: 100%;
    height: 35%;
    margin-top: 5px;
    border: solid 1px #ddd;
    box-sizing: border-box;
    resize: none;
    font-size: 1.05rem;
}

.news1-checkresultbtn{
    border-radius: 50px;
    font-size: 0.9rem;
    padding: 0.7rem 2.5rem;
    color: white;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.18);
  }
  

/*----------------------*/
/*news good*/

.newsgood-main{
    width: 100%;
    position: relative;
}
.newsgood-main img{
    width: 90%;
}
.newsgood-text{
    color: #555555;
    width: 34%;
    position: absolute;
    text-align: left;
    top: 20%;
    left: 11%;
}
.newsgood-text h3{
    color: #333333;
}
/*-------------*/

/*news2*/

.news2 {
    display: flex;
    flex-direction: column;
}

.news2-main {
    display: flex;
    justify-content: center;
}

.news2-btn {
    display: flex;
    align-self: flex-end;
    margin-right: 20%;
}

.news2-main-content {
    width: 45%;
    margin: 1rem;
}
#news2-main-content-left{
    border-right: dashed 3px #ddd;
}
#news2-main-content-right {
   padding-top: 2rem;
}

.news-models {
    display: flex;
    justify-content: center;
    align-items: center;
}

.news-model-choice {
    display: flex;
    flex-direction: column;
    width: 22%;
    margin: 0rem 1rem;
    cursor: pointer;

    filter: grayscale(100%);
    /* 흑백 효과 */
    transition: all 0.3s ease;
}

.news-model-choice:hover {
    filter: grayscale(0%);
}

.news-model-choice.selected {
    filter: grayscale(0%);
}

.news-model-choice.selected img {
    opacity: 1;
    border: none;
}


.news2-textzone {
    display: flex;
    flex-direction: column;
    text-align: left;
}


.news2-gridzone {
    margin: 1rem 2rem;
}

.news2-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.news2-grid-item {
    border: solid 1px #dddddd;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
}
.news2-grid-item p{
    margin: 8%;
}

.news2-grid :hover {
    background-color: #00bc8e;
    color: white;
}

.news2-grid-item.checked{
    background-color: #00bc8e;
    color: white;
    font-weight: bold;
}
.news2-grid-item.checked::before {
    content: '\2713';
    /* 체크 표시에 사용할 유니코드 문자 (\2713은 체크 표시) */
    position: absolute;
    top: 3%;
    left: 10%;
    font-size: 2rem;
    font-weight: bold;
    color: white;
}
/*genderage1*/

.genderage1-imagecard-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.genderage1-imagecard{
    border: solid 1px #ddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
}
.genderage1-imagecard img{
    width: 80%;
    margin-top: 1rem;
}

.genderage1-imagecard-ele{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 3% 0%;
    border-bottom: solid 1px #ddd;
    color: #666666;
    gap: 2rem;
}
.genderage1-imagecard-ele p{
    margin: 0;
}
.genderage1-imagecard-ele select{
    flex-grow: 1;
    border-color: #ddd;
    border-radius: 4px;
    height: 1.7rem;
    cursor: pointer;
    background:url('assets/supervised/genderage/arrow.png') no-repeat 92% 50%/10px auto;
    appearance: none;
    
}
.genderage1-imagecard-ele select:focus{
    border-color: orange;
    outline: none;
}

.genderage1-select-false{
    color: #FED800;
}

/*genderage3*/

#genderage3-table-scollbar::-webkit-scrollbar{
    width: 5px;
}

#genderage3-table-scollbar::-webkit-scrollbar-thumb{
    background-color: #818181;
}

/*genderage4*/

.genderage4-imagecard-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}
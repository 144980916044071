/*prediction1*/

.prediction1-table-space {
    display: flex;
    justify-content: center;
}

.prediction1-table-container {
    width: 94%;
    max-height: 400px;
    overflow: auto;
}
.prediction1-table-container::-webkit-scrollbar{
    width: 5px;
}
.prediction1-table-container::-webkit-scrollbar-thumb{
    background-color: #818181;
    height: 40%;
}

.prediction1-table {
    border: solid 1px grey;
    border-collapse: collapse;
    width: 100%;
}

.prediction1-table th,
.prediction1-table td {
    border: 1px solid #ddd;
    padding: 8px;
    /* width: 5%; */
}

.prediction1-table thead th {
    background-color: #525364;
    color: white;
    position: sticky;
    font-weight: normal;
    top: -0.5px;
}
.prediction1-table td{
    font-size: 0.9rem;
}

/*----------------------*/
/*prediction3*/

.prediction3-chart-space {
    padding: 0% 3%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}
/*labeling*/
.label-space {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-space>div {
    margin: 0rem 1rem;
    width: 22rem;
    transition: all 0.3s ease;
}

.label-space>div:hover {
    cursor: pointer;
    opacity: 50%;
}


.labeling-selected img {
    cursor: pointer;
    opacity: 60%;
    border: solid 3px #503CB2;
}



/*------------------------*/

/*object1*/



.object-labeling-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 2% 4%;
    justify-content: center;
}

.object-labeling-grid-item {
    background-repeat: no-repeat;
    background-size: cover;
    border: solid 3px white;
    border-radius: 20px;
    padding: 15px;
    min-height: 20rem;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    filter: grayscale(100%);
    /* 흑백 효과 */
    transition: all 0.3s ease;
}

.object-labeling-grid-item:hover {
    filter: grayscale(0%);
    border: solid 3px #503CB2;
    transform: scale(1.05);
}

.object-labeling-grid-item.labelingtrue {
    filter: grayscale(0%);
    border: solid 3px #503CB2;
}

/*----------------------------*/


/*object2*/


.object2-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 2%;
}

.object2-main-item img {
    width: 50%;
}

.object2-main-item h2 {
    margin-top: 0;
    color: #63ccd6;
}

/*object3*/


.object3-grid {
    display: grid;
    padding: 2%;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    max-height: calc((2 * 15rem) + (2 * 1rem));
    overflow-y: auto;
}
.object3-grid::-webkit-scrollbar{
    width: 5px;
}
.object3-grid::-webkit-scrollbar-thumb{
    background-color: #818181;
}

.object3-grid div :hover{
    cursor: pointer;
    opacity: 50%;
}
.object3-grid img{
    width: 100%;
    height: 100%;
}


.object3-result{
    margin : 2% 5%;
    display: flex;
    justify-content: center;
    gap: 1rem;
}


.object3-result-btn-group{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.object3-result-btn{
    border: solid 1px #dddddd;
    background-color: white;
    color: #555555;
    font-weight: bold;
    height: 10%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.object3-result-btn:hover{
    background-color: #3c538e; 
    color: white;
    transition: 0.3s;
}
.object3-selected {
    background-color: #3c538e; 
    color: white;
  }

/*writing1*/

.writing-result {
    display: flex;
    align-items: center;
}

.writing-result-text {
    margin: 0% 2% 0% 4%;
    padding: 0% 2%;
    text-align: left;
    background-color: #f9f9f9;
    height: 5rem;
    display: flex;
    align-items: center;
    border-radius: 20px;
}


.writing1-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.writing1-left {
    border-right: dashed 3px #ddd;
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 0rem 2rem;
}

.writing1-left-keyword {
    display: flex;
    text-align: left;
    height: 6rem;
    align-items: center;
    justify-content:flex-start;
}
.writing1-left-keyword div{
    background-color: #F9F9F9; 
    border-radius: 20px;
    padding: 0% 8%;
}

.writing1-left-keyword-text {
    width: auto;
    padding-bottom: 5px;
    border-bottom: solid 1px #DDDDDD;
}

.writing1-left-range {
    text-align: left;
}

.writing1-left-range-slider {
    border: solid 1px #DDDDDD;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0%;
}

.writing1-left-range-slider input {
    width: 80%;
    cursor: pointer;
}

.writing1-input-slider {
    -webkit-appearance: none;
    height: 20px;
    background: #666666;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 10px;
}

.writing1-input-slider:hover {
    opacity: 0.7;
}

.writing1-input-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #F0940A;
    cursor: pointer;
}

.writing1-range-text {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.writing1-range-ele {
    padding: 5px;
    border-radius: 3px;
    border: solid 1px;
    font-size: 0.7rem;
    background-color: white;
    color: black;
}

.writing1-range-ele2 {
    opacity: 0;
    padding: 5px;
    border-radius: 3px;
    border: solid 1px;
    font-size: 0.7rem;
    background-color: white;
    color: black;
}

.range-this {
    opacity: 1;
    background-color: #F0940A;
    color: white;
}

.writing1-left-range-slider datalist {
    display: flex;
    justify-content: space-between;
    width: 80%;
}
#writing1-tickmarks {
    color: #b8b8b8;
    font-size: 0.4rem;
}
#writing1-tickmarks2 {
    color: black;
    font-size: 0.6rem;
}

.writing1-left-keywordselec {
    text-align: left;
}

.writing1-left-keyword-space {
    border: solid 1px #DDDDDD;
    border-radius: 15px;
    padding: 4%;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
}

.writing1-left-keyword-space div {
    background-color: #21c997;
    color: white;
    border-radius: 10px;
    text-align: center;
}

.writing1-left-keyword-space p {
    margin: 5% 0%;
}

.writing1-left-keyword-space p:hover {
    color: #fed800;
    cursor: pointer;
    transition: 0.3s;
}

.writing1-right {
    width: 90%;
    padding: 0rem 2rem;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}
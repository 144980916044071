/*celebrity1*/

.mbtiArea{
    display: grid;
    grid-template-columns: repeat(4, 1fr);

}
.mbtiList{
    width: 90%;
}

.mbtiType dl dt{
    background-color: #337DC6;
    height: 3rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.mbtiType dl dd{
    background-color: #DDEDFD;
    color: #6175C9;
    width: 100%;
    margin: 0%;
    margin-top: 4%;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mbtiNum ul{
    padding: 0%;
}
.mbtiNum ul li{
    border: solid 1px #DDDDDD;
    list-style-type: none;
    width: 100%;
    cursor: pointer;
    margin: 5% 0%;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.mbtiNum ul li.selected{
    background-color: #FED800;
    color: #A47300;
}
.mbti-check{
    background-color: white;
    border-radius: 50px;
    width: 18px;
    height: 18px;
    color: #F70C41;
    border: solid 1px #F70C41;
    position: absolute;
    top: -18%;
    left: -5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.diagramList{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.diagramList img{
    width: 65%;
}
.diagramWrap{
    position: absolute;
    cursor: pointer;
}

.diagramTitle{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.8rem;
    color: rgba(255,255,255, 0.6);
}
.diagramTitle p{
    margin: 0;
}
.dTitle{
    color: white;
    font-size: 1rem;
}
.diagram-right{
    background-color: #FF8C00;
}
.diagram-center{
    background-color: #0075FF;
}
.diagram-left{
    background-color: #F70C41;
}

#first-choice {
    background-color: black;
}

#second-choice {
    background-color: black;
}

#third-choice {
    background-color: black;
}
.diagram-check{
    background-color: white;
    border-radius: 50px;
    width: 18px;
    height: 18px;
    color: #F70C41;
    border: solid 1px #F70C41;
    position: absolute;
    top: 0%;
    left: -4%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mbti01{top: -2%; left: 55%;}
.mbti02{top: 15%; left: 72%;}
.mbti03{top: 45%; left: 75%;}
.mbti04{top:75%; left: 68%;}
.mbti05{top:88%; left: 46%;}
.mbti06{top: 80%; left: 25%;}
.mbti07{top: 54%; left: 13%;}
.mbti08{top: 24%; left: 13%;}
.mbti09{top: 2%; left: 27%;}

.diagram-space-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 0.9rem;
}
.diagram-space-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 0.9rem;
}
.diagram-space-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 0.9rem;
}







/*----------------------*/

/*celebrity2*/

.celebrity2-cluster-space{
    display: grid;
    grid-template-columns: 7fr 3fr;
    padding: 1rem 0rem;
}

.celebrity2-cluster-space-left{
    border-right: solid 2px #d1d1d1;
    border-right-style: dotted;
    display: flex;
    align-items: center;
    justify-content: center;
}

.celebrity2-chart{
    width: 95%;
}
.celebrity2-chart h3{
    text-align: left;
    margin-top: 0;
}
.celebrity2-chart b{
    color: #FED800;
    margin: 0% 1% 0% 2%;
    font-size: 1.5rem;
}

.celebrity2-table{
    width: 100%;
    border-collapse: collapse;
    padding: 0% 2%;
}
.celebrity2-table h3{
    text-align: left;
    margin-top: 0;
}
.celebrity2-table b{
    color: #FED800;
    margin: 0% 1% 0% 2%;
    font-size: 1.5rem;
}

.celebrity2-table-space{
    display: flex;
    justify-content: center;
}

.celebrity2-table-container{
    width: 94%;
    max-height: 400px;
    overflow: auto;
}

.celebrity2-table td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    color: #666666;
}

.celebrity2-top{
    display: flex;
    align-items: center;
    padding: 0% 4%;
    color: #666666;
}

.celebrity2-pulsminus{
    border: solid 1px #E5E6E7;
    height: 27px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.celebrity2-top input{
    border: solid 1px #E5E6E7;
    width: 4rem;
    height: 25px;
    text-align: center;
}
.celebrity2-top label{
    padding: 0% 1%;
}
.celebrity2-top div{
    cursor: pointer;
}
.celebrity2-execute{
    margin: 0% 1%;
    border: solid 1px #00479F;
    color: #00479F;
    border-radius: 5px;
    width: 70px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.celebrity2-execute:hover{
    background-color: #00479F;
    color: white;
}

.celebrity2-find{
    border: solid 1px #CECECE;
    color: #666666;
    background-color: #EEEEEE;
    border-radius: 5px;
    width: 140px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.celebrity2-find:hover{
    background-color: #E6E6E6;
}


/*-------------------*/
/*celebrity3*/

.celebrity3-table{
    width: 100%;
    border-collapse: collapse;
    padding: 0% 2%;
}
.celebrity3-table h3{
    text-align: left;
    margin-top: 0;
}
.celebrity3-table b{
    color: #FED800;
    margin: 0% 1% 0% 2%;
    font-size: 1.5rem;
}

.celebrity3-td{
    border: solid 1px #DDDDDD;
    color: #666666;
}
.celebrity3-td-back{
    background-color: #f9f9f9;;
}

.celebrity3-re-btn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.celebrity3-re-btn a{
    color: white;
    background-color: #21C997;
    padding: 0.4rem 1rem;
    border-radius: 5px;
}
.celebrity3-re-btn a:hover{
    background-color: #10B483;
}
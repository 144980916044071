/*coldpatient1*/

.coldpatient1-table-space {
    display: flex;
    justify-content: center;
}

.coldpatient1-table-container {
    width: 94%;
    max-height: 400px;
    overflow: auto;
}

.coldpatient1-table-container::-webkit-scrollbar{
    width: 5px;
}
.coldpatient1-table-container::-webkit-scrollbar-thumb{
    background-color: #818181;
    height: 40%;
}

.coldpatient1-table {
    border: solid 1px grey;
    border-collapse: collapse;
}

.coldpatient1-table th,
.coldpatient1-table td {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: center;
    /* white-space: nowrap;
    overflow: visible; */
    width: 1%;
}

.coldpatient1-table thead th {
    background-color: #525364;
    color: white;
    position:sticky;
    top: -0.5px;
    font-weight: normal;
}

/*---------------------*/

/*coldpatient2*/

.coldpatient2-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
}


/*----------------------*/


/*colepatient3*/

.coldpatient3-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.coldpatient3-main-left {
    width: 30%;
    padding-right: 30px;
}

.coldpatient3-main-right {
    width: 50%;
    padding: 5px;
    border-left: dashed 3px #ddd;
}


.coldpatient3-main-right img {
    width: 70%;
}


.coldpatient3-table-space {
    display: flex;
    justify-content: center;

}

.coldpatient3-table-container {
    max-height: 400px;
    overflow: auto;
    width: 80%;
}
.coldpatient3-table-container::-webkit-scrollbar{
    width: 5px;
}
.coldpatient3-table-container::-webkit-scrollbar-thumb{
    background-color: #818181;
    height: 40%;
}

.coldpatient3-table {
    border: solid 1px grey;
    border-collapse: collapse;
    width: 100%;
}

.coldpatient3-table th,
.coldpatient3-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    /* white-space: nowrap;
    overflow: visible; */
    width: 1%;
}

.coldpatient3-table thead th {
    background-color: #525364;
    color: white;
    position: sticky;
    font-weight: normal;
    top: -0.5px;
}

/*-------------------------*/
/*coldpatient4*/

.coldpatient4-re-btn{
    background-color: #21C997;
    width: 5rem;
    padding: 5px;
    border-radius: 5px;
    float: right;
    margin-top: 1%;
    margin-right: 3%;
}
.coldpatient4-re-btn a{
    color: white;
}
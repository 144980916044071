/*emotion1*/


.emotion1-grid-container {
    border: solid 1.2px #a2a2a2;
    margin: 0% 5%;
    width: 90%;
}

.emotion1-grid-selectspace{
    max-height: 20rem;
    overflow-y: scroll;
}

.emotion1-grid-selectspace::-webkit-scrollbar{
    width: 5px;
}
.emotion1-grid-selectspace::-webkit-scrollbar-thumb{
    background-color: #818181;
}


.emotion1-grid {
    display: grid;
    align-items: center;
    grid-template-columns: 8.5fr 1.5fr;
    border-bottom: solid 1px #ddd;
    padding: 1% 0%;
}

.emotion1-grid p {
    margin: 0%;
}

.emotion1-grid:hover {
    background-color: #E8F4FE;
}

.emotion1-grid select {
    cursor: pointer;
    border: solid 1px #ddd;
    border-radius: 5px;
    height: 2rem;
    width: 80%;
}

.emotion1-grid select:hover {
    background-color: #E8F4FE;
}

.emotion1-grid select:focus {
    border-color: orange;
    background-color: #E8F4FE;
    outline: none;
}

.emotion1-grid-result {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    border-bottom: solid 1px #ddd;
    padding: 1% 0%;
}

.emotion1-grid-result p {
    margin: 0%;
}

.emotion1-grid-result:hover {
    background-color: #E8F4FE;
}

/*--------------*/

/*emotion4*/

.emotion4-re-btn-space{
    display: flex;
    justify-content: flex-end;
}
.emotion4-re-btn{
    background-color: #21C997;
    width: 5rem;
    padding: 5px;
    border-radius: 5px;
}
.emotion4-re-btn a{
    color: white;
}

.emotion4-main {
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: 1rem;
}

.emotion4-coment-list {
    border: solid 1px #ddd;
    border-radius: 15px;
    height: 43rem;
    overflow-y: scroll;
    padding: 10px;
}
.emotion4-coment-list::-webkit-scrollbar{
    width: 5px;
}
.emotion4-coment-list::-webkit-scrollbar-thumb{
    background-color: #818181;
}

.emotion4-talker-space {
    border-radius: 20px;
    border-bottom: solid 1px #ddd;
    display: flex;
    align-items: center;
    align-items: flex-start;
    padding: 5% 2% 5% 5%;
    cursor: pointer;
    gap: 1rem;
}

.emotion4-talker-space img {
    width: 20%;
    border-radius: 50%;
}

.emotion4-talker-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.emotion4-talker-nickname {
    border: solid 1px #0030BC;
    border-radius: 20px;
    color: #0030BC;
    padding: 0% 5%;
}


.emotion4-talker-document {
    text-align: left;
    border: solid 1px #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px;
    color: #666666;
   width: 80%;
}

.clickedtalker{
    background-color: #F6C1C1;
    color: black;
}

.emotion4-resulttable-space{
    padding: 0% 2%;
    height: 100%;
}
.emotion4-resulttable-space h3{
    text-align: left;
    margin-bottom: 0;
}

.emotion4-grid-top{
    min-height: 15rem;
}

.emotion4-tree-top{
    display: flex;
    justify-content:flex-end;
}

.emotion4-tree-top-text{
    display: flex;
    align-items: center;
}

.emotion4-tree-top-text div{
    border: solid 1px;
    width: 10px;
    height: 10px;
    margin: 0px 5px 0px 10px;
}
#emotion4-neg{background-color: #F6C1C1;}
#emotion4-neu{background-color: #FFFFFF;}
#emotion4-pos{background-color: #8CEF8C;}



.emotion4-top-table {
    border: solid 1px #ddd;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr 1fr 5fr;
    align-items: center;
}
.emotion4-top-table div{
    border: solid 1px #ddd;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#border{
    border-bottom: solid 1px #666666;
}
.emotion4-top-table span{
    border: solid 1px #ddd;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
}


.emotion4-grid-container {
    border: solid 1.2px #a2a2a2;
    border-radius: 3px;
    width: 100%;
    margin-top: 1rem;
}

.emotion4-grid {
    display: grid;
    align-items: center;
    grid-template-columns: 2.5fr 7fr;
    border-bottom: solid 1px #ddd;
    padding: 1% 0%;
    text-align: left;
}

.emotion4-grid p {
    margin: 0%;
    padding-left: 4%;
}

.emotion4-grid:hover {
    background-color: #E8F4FE;
}

.emotion4-grid-inside{
    overflow-y: scroll;
    height: 22rem;
    font-size: 0.9rem;
}

.emotion4-grid-inside::-webkit-scrollbar{
    width: 5px;
}
.emotion4-grid-inside::-webkit-scrollbar-thumb{
    background-color: #818181;
}
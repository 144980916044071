
.selfdriving2-main {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}

.selfdriving2-main img {
    width: auto;
    height: 100%;
    margin-top: 3rem;
}



.learningmodel-main-btn {
    display: flex;
    flex-direction: column;
}

.learningmodel {
    display: flex;
    flex-direction: column;
    margin: 0rem 2rem 4rem 2rem;
    width: 60%;
    height: 25rem;
}



/*palygame*/

.selfdriving-playgame {
    display: grid;
    justify-content: center;
    grid-template-columns: 65% 35%;
    padding: 1rem 0rem;
}

.selfdriving-playgame-left {
    border-right: solid 2px #d1d1d1;
    border-right-style: dotted;
}

.selfdriving-playgame-right {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.selfdriving-game iframe {
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
}

.selfdriving-playgame-desc {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -1px;
    text-align: left;
    color: #333333;
}

.selfdriving-record {
    border: solid 1px #ddd;
    background-color: #f5f5f5;
    padding: 4% 6%;
    font-weight: bold;
}

.selfdriving-record-div b {
    color: #63ccd6;
}
.selfdriving-record-div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: right;
    width: 60%;
    margin: 3% 0%;
}
.selfdriving-record-div p,b{
    margin: 0;
}


/*learningmodel*/

.selfdriving3-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3%;
    height: 100%;
    width: 100%;
}

.selfdriving-learningmodel {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 30rem;
    height: 75%;
    position: relative;
}
.selfdriving-learningmodel p{
    position: absolute;
    color: rgb(255, 21, 21);
    font-weight: 500;
    top:-12%;
    left: 40%;
}

.selfdriving-learningmodel-simulation {
    background-color: #F0F0F0;
    border: solid 1px #ddd;
    width: 100%;
    height: 100%;
}

.selfdriving-learningmodel-btn-group {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    gap: 2rem;
    width: 25rem;
    height: 24rem;
}

.selfdriving-h4 {
    margin: 0rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selfdriving-h4 h4 {
    margin: 0;
}

.selfdriving-learningmodel-btn {
    border: solid 1px #dddddd;
    background-color: white;
    color: #555555;
    font-weight: bold;
    width: 217px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selfdriving-learningmodel-btn:hover {
    background-color: #3c538e;
    color: white;
    transition: 0.3s;
}

.selfdriving-button-selected {
    background-color: #3c538e;
    color: white;
}

.selfdriving-learningmodel-btn-group-item {
    display: flex;
}


/*selfdriving2*/

.selfdriving2-simulation{
    width: 35rem;
    border: solid 2px #ddd;
    padding: 1.5rem 0rem;
}

.selfdriving2-main {
    position: relative;
    margin-top: 1rem;
}

.selfdriving2-main-desc {
    width: 50%;
    text-align: left;
    color: white;
    position: absolute;
    top: 15%;
    left: 36%;
}

.selfdriving2-main-desc h3{
    color: #ff8a00;
    letter-spacing: -1.2px;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
}

.selfdriving2-main-step-desc{
    background-color: #0b3228;
    padding: 0.5rem 2rem;
    border-radius: 20px;
    font-size: 0.95rem;
    word-break: keep-all;
}
.selfdriving2-main-step-desc b{
    color: #ffd800;
    font-size: 1.1rem;
}

.selfdriving2-underline-zone{
    position: relative;
}
.selfdriving2-underline-zone img{
    position: absolute;
    top:-2.5rem;
    left: -2rem;
    width: 65%;
}


.introduce-header{
    width: 100%;
    height: 10rem;
    position: absolute;
}
.introduce-header img{
    width: 100%;
    height: 10rem;
    filter: brightness(50%);
}

.introduce-header p{
    position: relative;
    top: -90%;
    color: white;
    font-size: 2.5rem;
    font-weight: 500;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
}

.introduce-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.introduce-manual-btn{
    width: 14rem;
    background-color: #00479F;
    border-radius: 7px;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    cursor: pointer;
    margin-top: 15px;
  }
  .introduce-manual-btn a{
    color: white;
  }
  .introduce-manual-btn:hover{
    background-color: #0059C6;
  }

.introduce-class{
    width: 80%;
    border-radius: 15px;
    margin-bottom: 2rem;
    text-align: left;
    padding: 1rem 2rem;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #F7F7F7;
}

.introduce-model-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}
.introduce-grid-item{
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem 0rem;
}
.introduce-grid-item p{
    margin: 0;
    padding: 0rem 1rem;
}
.introduce-grid-item b{
    color: #FED800;
    font-size: 1.2rem;
}
.introduce-grid-item img{
    width: 100%;
    height: 11rem;
    margin: 1rem 0rem;
}
#introduce-green{
    font-size: 1rem;
    font-weight: 500;
    color: #41BC8A;
}
#introduce-blue{
    color: #3880C7;
}
#introduce-desc{
    font-size: 0.9rem;
}


/*TermsofUse*/

.termsofuse-main-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.termsofuse-main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 62%;
    color: #666666;
    text-align: left;
}
.termsofuse-title{
    color: black;
    font-size: 1.3rem;
}
.termsofuse-sub-title{
    border: solid 1px #00479f;
    color: #00479f;
    border-radius: 30px;
    padding: 2px 15px;
}
.termsofuse-space{
    margin: 0 1rem;
}
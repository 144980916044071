/*-----cartpole1-------*/

.cartpole-playgame {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 0rem;
}

.cartpole-playgame-left {
    border-right: solid 2px #d1d1d1;
    border-right-style: dotted;
}

.cartpole-playgame-right {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cartpole-game iframe {
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
}

.cartpole-playgame-desc {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -1px;
    text-align: left;
    color: #333333;
}

.cartpole-record {
    border: solid 1px #ddd;
    background-color: #f5f5f5;
    padding: 4% 3%;
    font-weight: bold;
}

.cartpole-record-div b {
    color: #63ccd6;
}
.cartpole-record-div{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: right;
    width: 35%;
    margin: 3% 0%;
}
.cartpole-record-div p,b{
    margin: 0;
}

/*-------------------------*/

/*cartpole2*/

.cartpole2-main {
    position: relative;
    margin-top: 1rem;
}

.cartpole2-main-desc {
    width: 50%;
    text-align: left;
    color: white;
    position: absolute;
    top: 14%;
    left: 36%;
}



/*-------------------*/
/*--------cartpole3---------*/

.cartpole3-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3%;
    height: 100%;
}

.cartpole-learningmodel {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 30rem;
    position: relative;
}
.cartpole-learningmodel p{
    position: absolute;
    color: rgb(255, 21, 21);
    font-weight: 500;
    top:-10%;
    left: 50%;
}

.cartpole-learningmodel-simulation {
    /* border: solid 1px #3c538e; */
    background-color: #F0F0F0;
    border: solid 1px #ddd;
    width: 25rem;
    height: 25rem;
}

.cartpole-learningmodel-btn-group {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    gap: 2rem;
    width: 25rem;
    height: 24rem;
}

.cartpole3-h4 {
    margin: 0rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cartpole3-h4 h4 {
    margin: 0;
}

.cartpole-learningmodel-btn {
    border: solid 1px #dddddd;
    background-color: white;
    color: #555555;
    font-weight: bold;
    width: 217px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cartpole-learningmodel-btn:hover {
    background-color: #3c538e;
    color: white;
    transition: 0.3s;
}

.cartpole-button-selected {
    background-color: #3c538e;
    color: white;
}

.cartpole-learningmodel-btn-group-item {
    display: flex;
}
/*p0102*/

.calendar-space {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.calendar-space-left {
  width: 25%;
}

.calendar-space-right {
  width: 75%;
}

/*요소별 체크박스*/
.calendar-check-compo {
  display: flex;
  flex-direction: column;
}

.checkbox-label {
  display: flex;
  justify-content: left;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.checkbox-name {
  width: 80%;
  padding: 10px;
  border: solid 1px #999999;
  display: flex;
  align-items: center;
}

.checkbox-name:hover {
  background-color: #3c538e;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.checkbox-name.checked {
  background-color: #3c538e;
  color: white;
}

.checkbox-input {
  display: none;
}

.checkbox-custom {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 10px;
  border: 1px solid #999999;
  cursor: pointer;
  color: black;
  background-color: white;
}

.checkbox-custom::after {
  content: "✔";
  position: relative;
  display: none;
  font-size: 20px;
}

.checkbox-input:checked + .checkbox-custom::after {
  display: block;
  width: 8px;
  height: 8px;
  top: 0%;
  left: 30%;
  transform: translate(-50%, -50%);
}

/*달력*/

.calender {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border: solid 1px #eeeeee;
  width: 100%;
}

.num {
  border: solid 1px #eeeeee;
  min-height: 6rem;
  font-size: 0.9rem;
  padding-left: 10px;
  padding-top: 6px;
}

.cal-blank {
  border: solid 1px #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.num-ele {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  text-align: left;
}

.num-ele > img {
  width: 1.7rem;
}

.num-span {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  text-align: left;
}

.num-span div p {
  font-size: 10px;
  margin: 2% 0%;
}

/*----------------------*/

/*weather2*/

.multinomial-model {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3rem 0rem;
}

.multinomial-model-left {
  border-right: solid 2px #d1d1d1;
  border-right-style: dotted;
}

.multinomial-model-left img {
  width: 70%;
}

.multinomial-model-right {
  padding: 0rem 5rem 0rem 2rem;
  text-align: left;
}

/*-----------------------------*/
/*weather3*/
.weather3-boardzone {
  display: flex;
  justify-content: center;
}

.board-wrap {
  position: relative;
  display: flex;
  justify-content: center;
}

.board-img {
  width: 95%;
  margin-top: 2rem;
}

.board-text {
  position: absolute;
  top: 45%;
  left: 70%;
  transform: translate(-50%, -50%);
  padding: 0% 2%;
}

.green-board {
  color: white;
  text-align: left;
  font-size: 1.1rem;
  width: 25rem;
}

.green-board b {
  color: #fcff00;
}

.green-board-spinner {
  position: absolute;
  top: 23rem;
  left: 34rem;
  transform: translate(-50%, -50%);
}

/*----------------------*/

/*-----weather4--------*/

.weather4-descimg {
  width: 1.5%;
}

.calendar-space2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.calender2 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border: solid 1px #eeeeee;
  flex-grow: 1;
}

.num2 {
  border: solid 1px #eeeeee;
  min-height: 4.5rem;
  font-size: 0.9rem;
  padding-left: 5px;
  padding-top: 10px;
}

.num-span2 {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  text-align: left;
  font-size: 0.8rem;
  margin-top: 1rem;
}

.num-ox {
  position: absolute;
  width: 20px;
  margin: 23px 2.1%;
}

.num-ox:hover {
  cursor: pointer;
}

.weather4-popup {
  color: #555555;
  background-color: white;
  border-radius: 30px;
  padding: 20px;
  width: 30%;
  height: 20rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}

.weather4-popup p {
  margin: 0;
}

.weather4-popup button {
  border: solid 1px;
  width: 140px;
  height: 40px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #22194c;
  color: white;
}

.weather4-popup-video {
  width: 20rem;
  height: 100%;
}

/*-----------------------*/
/*weather5*/

.score-description {
  font-size: 1rem;
  text-align: left;
  width: 80%;
  color: white;
}

.score-description b {
  color: #fcff00;
}

.scoreboard-space {
  background-image: url("assets/supervised/weather/scoreboard.png");
  background-repeat: round;
  background-size: contain;
  height: 32rem;
  margin: 2%;
  display: flex;
  justify-content: center;
}

.scoreboard-score {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 45%;
}

.back-selectdata {
  margin: 1% 0% 1% 50%;
  border: solid 2px #66c3aa;
  border-radius: 20px;
  width: 20%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-selectdata a {
  color: #66c3aa;
}

.back-selectdata :hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.weather5-score-text {
  position: absolute;
  top: 17%;
}

.scoreboard-score img {
  position: absolute;
  width: 80%;
  top: 7%;
}

.weatehr5-rscore-space {
  position: absolute;
  text-align: left;
  color: white;
  width: 90%;
  top: 54%;
}

.weatehr5-rscore-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
}

.weatehr5-rscore-grid p {
  margin: 0.5rem 0rem;
}

#weather5-grid1 {
  background-color: #8faadc;
}

#weather5-grid2 {
  background-color: #2f5597;
}

#weather5-grid3 {
  background-color: #203865;
}

/*masterpiece1*/

.masterpiece1-select-painter {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 2%;
    gap: 10px;
}

.masterpiece1-painter {
    border: solid 1px #E0E0E0;
    border-radius: 10px;
    padding: 5%;
    cursor: pointer;
}
.masterpiece1-painter:hover{
    transition: 0.3s;
    transform:scale(1.05);
}
.masterpiece1-painter.selected{
    border-color: #503cb2;
}
.masterpiece1-painter img {
    width: 100%;
}

.masterpiece1-painter-text {
    text-align: left;
    color: #555555;
    border-bottom: solid 1px #E0E0E0;
    margin: 0% 0% 5% 0%;
    padding-bottom: 5%;
}

.masterpiece1-painter-text p {
    margin: 0;
}

/*--------------------*/

/*masterpiece2*/
.masterpiece2-tab{
    padding: 1% 2%;
}
.masterpiece2-tab-bar-container{
    display: flex;
}

.masterpiece2-picture-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding-top: 1rem;
}

.masterpiece2-tab-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px gray;
    flex-grow: 1;
}
.masterpiece2-tab-button p{
    font-size: 1rem;
    font-family: 'Pretendard';
}
.masterpiece2-tab-button:focus,
.masterpiece2-tab-button:hover{
    color: #337DC6;
    border-color: #337DC6;
    font-weight: bold;
    cursor: pointer;
}

.masterpiece2-tab-button.active{
    color: #337DC6;
    border-color: #337DC6;
    font-weight: bold;
}

.masterpiece2-picture-card{
    border: solid 1px #E0E0E0;
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
}
.masterpiece2-picture-card:hover{
    transition: 0.3s;
    transform: scale(1.05);
}
.masterpiece2-picture-card.selected{
    border-color: #503cb2;
}


/*------------------------*/


/*masterpiece3*/


.masterpiece3-parameter-space{
    display: grid;
    grid-template-columns: 6fr 4fr;
    padding: 2rem 0rem;
}

.masterpiece3-parameter-space-left{
    border-right: solid 2px #d1d1d1;
    border-right-style: dotted;
    display: flex;
    align-items: center;
    justify-content: center;
}
.masterpiece3-parameter-space-left img{
    width: 90%;
}

.masterpiece3-parameter-space-right{
    padding: 0.5rem 2rem 1rem 2rem;
    text-align: left;
}

.masterpiece3-tableinput h3{
    margin: 0px 10px 10px 10px;
    padding-bottom: 5px;
    border-bottom: solid 1px;
}

.masterpiece3-table-space{
    display: flex;
    justify-content: center;
}

.masterpiece3-table-container{
    width: 94%;
    max-height: 400px;
    overflow: auto;
}
.masterpiece3-table{
    width: 100%;
    border-collapse: collapse;
}

.masterpiece3-table td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: 0.9rem;
}
.masterpiece3-table input{
    background-color: #EEEEEE;
    border-radius: 4px;
    border: none;
    padding: 5px;
}

/*--------------*/

/*masterpiece4*/

.masterpiece4-main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 3rem;
    min-height: 28rem;
    margin: 3rem 2rem 1rem 2rem;
}

.masterpiece4-main-left{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: dashed 3px #ddd;
}
.masterpiece4-main-left img{
    width: 25%;
}
.masterpiece4-main-left-textdiv{
    border-radius: 50px;
    position: absolute;
    color: white;
    padding: 0% 4%;
    background-color: #FF8C00;
}

.masterpiece4-main-right{
    padding: 5%;
}
.masterpiece4-main-right img{
    width: 80%;
}
.flappy1-main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.flappy1-main h3 {
    text-align: left;
}

.flappy1-main-left {
    border-right: dashed 3px #ddd;
}

.flappy1-main-right,
.flappy1-main-left {
    padding: 0% 4%;
}


/*learning model*/
.flappy-main {
    width: 100%;
    gap:1rem;
    padding-bottom: 1rem;
}

.flappy-learningmodel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.flappy-learningmodel p{
    position: absolute;
    color: rgb(255, 21, 21);
    font-weight: 500;
    top:-7%;
    left: 40%;
}

.flappy-learningmodel-simulation {
    background-color: #F0F0F0;
    border: solid 1px #ddd;
    height: 40rem;
    width: 25rem;
}

.flappy-learningmodel-btn-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}
.flappy-main h4{
    margin: 0;
}

.flappy-learningmodel-btn {
    border: solid 1px #dddddd;
    background-color: white;
    color: #555555;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
}

.flappy-learningmodel-btn:hover {
    background-color: #3c538e;
    color: white;
    transition: 0.3s;
}

.flappy-button-selected {
    background-color: #3c538e;
    color: white;
}


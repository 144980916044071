body {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'Pretendard';
}

.routing {
  word-break: keep-all;
  margin: 0;
  padding: 0% 10% 2% 10%;
  background-color: #F2F5F6;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

img {
  max-width: 100%;
}

Link,
a {
  text-decoration: none;
  color: black;
}

button {
  background-color: white;
  border: none;
  cursor: pointer;
}

/*Nav Bar*/
.navbar-menu {
  display: flex;
  align-items: center;
  margin: 0%;
  height: 6rem;
  padding: 0% 16%;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 6rem;
  line-height: 1.2;
  letter-spacing: -1px;
  text-align: center;
  color: #444;
}

.nav-item:hover {
  background-color: #f4ce14;
  transition: 0.25s;
}

.hover-effect {
  margin: 0rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 6rem;
  line-height: 1.2;
  letter-spacing: -1px;
  text-align: center;
  color: #444;
}

.nav-image {
  width: 90%;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
  z-index: 2;
}

.dropdown-content {
  display: none;
  cursor: default;
  position: absolute;
  background-color: white;
  font-weight: 500;
  font-size: 0.9rem;
  top: 6rem;
  left: 0%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  transform: translateY(-5%);
  transition: transform 0.3s ease-in-out;
}

.dropdown-content.open {
  transform: translateY(0);
}

.dropdown-grid {
  padding: 6%;
  display: grid;
  grid-template-columns: 20rem 20rem;
  gap: 1rem;
}

.dropdown-grid-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.dropdown-grid-item p {
  text-align: left;
  margin: 0;
  color: #999999;
}

.dropdown-grid-item a {
  color: #555555;
}

.dropdown-grid-item a:hover {
  color: #63CCD6;
  text-decoration-line: underline;
}

.dropdown-grid-item .model-space-desc-class {
  margin-bottom: 6px;
}


/*footer*/

footer {
  height: 9rem;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #777777;
  padding: 0% 17%;
}

footer img {
  width: 10%;
}

.footer-desc {
  text-align: left;
  margin: 0% 3%;
  font-size: 0.8rem;
}

.footer-desc-first {
  background-color: #4A5569;
  border-radius: 50px;
  font-size: 0.8rem;
  width: 20%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-desc-first a{
  color: white;
}
.footer-desc-first a:hover{
  color:thistle;
}


/*------------------------*/

/*header*/
.header-upper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem 0 2rem;
}

.header-blank {
  width: 70%
}

.header-logout-btn {
  width: auto;
  border-style: none;
  background-color: white;
  box-shadow: 1px 1px 1px 1px;
  font-weight: bold;
}

.header-logout-btn:hover {
  cursor: pointer;
}

.header-std {
  width: 10%;
  text-align: center;
}

.header-std>p {
  margin: 0;
}

header {
  padding: 0% 8%;
  height: 5rem;
  display: flex;
  flex-direction: column;
  font-size: 1.7rem;
  letter-spacing: -1.7px;
}

/*--------------------------*/

/*Home*/

.home-main {
  color: #344767;
}

.home-header {
  color: #0F358F;
  padding: 3% 16%;
  margin-top: 0rem;
  text-align: left;
  display: flex;
  font-size: 2rem;
  font-weight: 800;
  line-height: 0.6;
  letter-spacing: -2px;
  justify-content: space-between;
}

.home-header img {
  width: 40%;
}


.home-header-underbar {
  width: 5rem;
  border-bottom: solid 1px #63CCD6;
}

.home-header-desc {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.5px;
  color: #777;
}

.tab {
  padding: 0% 5%;
}

.tab-bar-container {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.button-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0% 4%;
  gap: 1rem;
}

.tab-button {
  height: 3rem;
  width: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: #a0a0a0;
  margin: 0.3rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.tab-button p {
  font-size: 1.1rem;
  line-height: 1.33;
  letter-spacing: -0.9px;
  font-family: 'Pretendard';
}

.tab-button:focus,
.tab-button:hover {
  background: #0F358F;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s;
}

.tab-button.active {
  background: #0F358F;
  color: white;
  font-weight: bold;
}

.model-space {
  text-align: left;
  font-size: 0.9rem;
  color: #344767;
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.model-space:hover {
  transform: scale(1.03);
  transition: 0.5s;
}

.model-space img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  height: 60%;
  width: 100%;
}

.model-space-desc {
  padding: 0.5rem 1rem;
}

.model-space-desc-class {
  font-size: 0.7rem;
  background-color: #63CCD6;
  color: white;
  border-radius: 50px;
  width: 4rem;
  text-align: center;
  padding: 1.5%;
}
#dropdown-model-class:hover{
  cursor: pointer;
  transition: 0.3;
  transform: scale(1.1);
}



/*---------------------*/

/*main*/

main {
  padding-bottom: 2rem;
  margin: 0% 8%;
}

.main-step-img {
  display: flex;
  justify-content: center;
}

.main-step-img img {
  width: 70%;
}

.white-back {
  background-color: white;
  border-radius: 30px;
  padding: 20px;
  margin: 20px 0px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  letter-spacing: -0.8px;
}

.grey-back {
  background-color: #F5F5F5;
  border-radius: 20px;
  text-align: left;
  padding: 1px;
  color: #555555;
}


.main-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-desc-p {
  border-radius: 15px;
  background-color: white;
  text-align: left;
  padding: 0.5% 2%;
  font-size: 1rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  width: 96%;
  word-break: keep-all;
}

.main-blue {
  background-color: rgb(218, 227, 243);
  margin: 2rem 10rem 1rem 10rem;
  border-radius: 15px;
  padding: 0.1px;
}



.main-card {
  display: flex;
  width: 100%;
  margin: 0% 0% 4% 0%;
  gap: 1rem;
}

.card {
  min-height: 15rem;
  background-color: white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  padding: 2% 0%;

  >button {
    color: white;
    background-color: #3188BD;
    border-radius: 50px;
    width: 5rem;
    height: 1.5rem;
    margin-right: 50%;

    >h5 {
      margin: 0;
    }
  }

  >img {
    margin: 5% 0%;
  }
}

.main-btn {
  margin: 2rem 0rem;
}

.conceptcheck {
  text-align: left;
  height: 3rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 0% 8%;
  color: #555555;
}

.conceptcheck img {
  width: 2%;
  margin-right: 10px;
}


/*------------------------*/


/*button component*/


.bluebtn {
  background-color: #22194c;
  border-radius: 50px;
  font-size: 0.9rem;
  padding: 0.7rem 2.5rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.18);
}

.bluebtn:hover {
  background-color: #103392;
  transition: 0.3s;
}


.greybtn {
  background-color: #737373;
  border-radius: 50px;
  font-size: 0.9rem;
  padding: 0.7rem 2.5rem;
  color: white;
  font-weight: bold;
  margin: 0.5rem;
  color: white;
  box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.18);
}

.greybtn:hover {
  background-color: gray;
  transition: 0.3s;
}


.modal-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* 배경 색상 및 투명도 설정 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* 다른 요소 위에 나타나도록 z-index 설정 */
}

.modal {
  color: #555555;
  background-color: white;
  border-radius: 30px;
  padding: 20px;
  width: 25%;
  height: 10rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 2rem;
}


.modal button {
  border: solid 1px;
  width: 140px;
  height: 40px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #22194c;
  color: white;
}

.manual-space{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0rem;
}
.manual-btn{
  width: 10rem;
  background-color: #00479F;
  border-radius: 7px;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  cursor: pointer;
}
.manual-btn a{
  color: white;
}
.manual-btn:hover{
  background-color: #0059C6;
}

/*-------------------*/

/*HeaderNav*/

.header-nav {
  display: flex;
  margin: 1% 8%;
  font-size: 1rem;
}

.header-nav-item {
  margin-right: 1%;
  width: 9rem;
  background-color: #e9ebec;
  border-radius: 50px;
}

.header-nav-item-in {
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 20px;
}

.header-nav-item img {
  width: 15%;
  height: 50%;
}

.header-nav-item a {
  color: gray;
}

.header-nav-item :hover {
  border-radius: 50px;
  background-color: #503CB2;
  color: white;
  transition: 0.5s;
}


.header-nav-item a.active {
  display: flex;
  align-items: center;
  padding-left: 20px;
  background-color: #503CB2;
  border-radius: 50px;
  color: white;
}


.header-nav-img {
  width: 150px;
  height: 60px;
  position: absolute;
  top: 9.8rem;
  right: 20%;
}



/*--------------------------*/


/*--------carousel--------------*/


.carousel {
  background-color: transparent;
  margin: 1% 0% 3% 0%;
  display: flex;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  height: 25rem;
}

.carousel-img {
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.carousel-img img{
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.carousel-caption {
  width: 35%;
  background-color: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.carousel-caption-desc {
  height: 65%;
  text-align: left;
  margin: 8%;
  word-break: keep-all;
}

.carousel-caption-desc p {
  font-size: 0.9rem;
}

.carousel-caption-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0% 8%;
}

.carousel-caption-button button {
  border: solid 2px #F0F0F0;
  color: #555555;
  border-radius: 50%;
  padding: 5px 12px;
  font-size: 1.3rem;
  margin-right: 10px;
}
.carousel-caption-button button:hover {
  border: solid 2px #ddd;
  transform: scale(1.1,1.1);
  transition: 0.3s;
}


/*Navigation*/


/*ProgressBar*/

.progressbar-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.progressbar {
  color: #555555;
  background-color: white;
  border-radius: 30px;
  padding: 20px;
  width: 13%;
  height: 25%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*------------------------*/

/*step box*/

.step-space{
  width: 70%;
}
.step-box{
  text-align: left;
  background-color: #63CDD7;
  color: white;
  padding: 2.5% 3%;
  border-radius: 22px;
  font-size: 1rem;
  word-break: keep-all;
}

.step-line{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2% 2% 1% 2%;
}
.step-line-circle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.step-line-circle div{
  border: solid 2px #63CDD7;
  background-color: #63CDD7;
  border-radius: 100px;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#step-circle-div{
background-color: white;
}

.step-line-circle img{
  width: 50%;
}

.step-line-border{
  height: 2px;
  background-color: #63CDD7;
  flex-grow: 1;
}

.step-text{
  display: flex;
  justify-content: space-between;
}
.step-text div{
  flex-grow: 1;
}
.step-text p{
  color: #555555;
  font-weight: bold;
  letter-spacing: -0.8px;
  font-size: 1rem;
  margin: 0;
  width: 5rem;
}